import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import parseUA from 'ua-parser-js'

import { AppStore } from './appStore'
import BrowserExtension from './browserExtension'
import { GooglePlay } from './googlePlay'
import { STORE_LINKS } from '../../constants'
import { useTrans } from '../../hooks/useTrans'

const {
    os,
    device: { type },
} = parseUA(window.navigator.userAgent)

const isMobile = ['mobile', 'tablet', 'wearable'].includes(type || '')

export const MarketLinks = () => {
    const linkText = useTrans('linkText')

    if (os.name === 'iOS') {
        return (
            <Wrapper isMobile={isMobile}>
                <GuideText>{linkText}</GuideText>
                <Container>
                    <Link to={STORE_LINKS.iOSWeb}>
                        <AppStore />
                    </Link>
                </Container>
            </Wrapper>
        )
    }

    if (os.name === 'Android') {
        return (
            <Wrapper isMobile={isMobile}>
                <GuideText>{linkText}</GuideText>
                <Container>
                    <Link to={STORE_LINKS.Android}>
                        <GooglePlay />
                    </Link>
                </Container>
            </Wrapper>
        )
    }

    return (
        <Wrapper isMobile={isMobile}>
            <GuideText>{linkText}</GuideText>
            <Container>
                <Link to={STORE_LINKS.iOSWeb}>
                    <AppStore />
                </Link>

                <Link to={STORE_LINKS.Android}>
                    <GooglePlay />
                </Link>

                <Link to={STORE_LINKS.Browser}>
                    <BrowserExtension />
                </Link>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div<{ isMobile: boolean }>`
    ${({ isMobile }) =>
        isMobile
            ? css`
                  position: absolute;
                  bottom: 83px;
                  left: 0;
                  right: 0;
              `
            : css``}
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const GuideText = styled.div`
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #696f8c;
`

type LinkProps = {
    children: ReactNode
    to: string
}
const Link = ({ children, to }: LinkProps) => {
    const handleClick = () => {
        window.open(to, '_blank')
    }

    return <LinkContainer onClick={handleClick}>{children}</LinkContainer>
}

const LinkContainer = styled.button`
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: none;
`
