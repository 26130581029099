import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'
import parseUA from 'ua-parser-js'

import { useTrans } from '../hooks/useTrans'

const {
    device: { type },
} = parseUA(window.navigator.userAgent)

const isMobile = ['mobile', 'tablet', 'wearable'].includes(type || '')

export const QRCode = () => {
    const moveToAppText = useTrans('moveToApp')
    const qrcodeText = useTrans('qrcode')
    if (isMobile) {
        return <TextContainer marginBottom={12}>{moveToAppText}</TextContainer>
    }

    return (
        <Wrapper>
            <TextContainer>{qrcodeText}</TextContainer>
            <Container>
                <QRCodeSVG
                    value={window.location.href}
                    style={{
                        width: 100,
                        height: 100,
                    }}
                />
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Container = styled.div`
    display: flex;
    width: 120px;
    height: 120px;
    border: 3px solid #3366ff;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
`

const TextContainer = styled.div<{ marginBottom?: number }>`
    text-align: center;
    color: #696f8c;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`
