// FIXME: fix links after release to stores
export const STORE_LINKS = {
    iOS: 'itms-apps://itunes.apple.com/app/id6502896387',
    iOSWeb: 'https://itunes.apple.com/app/id6502896387',
    Android: 'https://play.google.com/store/apps/details?id=io.klutch.wallet',
    Browser: 'https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi',
} as const

export const ROUTES = {
    AIRDROP: '/a/',
    REDIRECT: '/u/',
    DEEPLINK: '/d/',
} as const
