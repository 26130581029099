export const AppStore = () => {
    return (
        <svg width="135" height="41" viewBox="0 0 135 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5129_19700)">
                <path
                    d="M124.249 0.677254H10.7566C10.3429 0.677254 9.9342 0.677254 9.5216 0.679254C9.17621 0.681254 8.83358 0.687064 8.48488 0.691954C7.72733 0.699856 6.97157 0.75893 6.22416 0.868664C5.4778 0.980787 4.75482 1.19217 4.07967 1.49566C3.40534 1.80173 2.7892 2.19942 2.25358 2.67432C1.71514 3.14789 1.26632 3.69527 0.924356 4.29541C0.581453 4.89436 0.343683 5.53619 0.219258 6.19873C0.0936495 6.86044 0.0260586 7.52977 0.0170916 8.20073C0.00662229 8.50733 0.00551669 8.81495 0 9.12159V32.2359C0.00551669 32.5464 0.00662229 32.8472 0.0170916 33.1578C0.0260614 33.8287 0.0936522 34.498 0.219258 35.1597C0.343339 35.8226 0.581124 36.4648 0.924356 37.064C1.26616 37.6622 1.71505 38.2073 2.25358 38.6783C2.78717 39.1553 3.40371 39.5532 4.07967 39.857C4.75481 40.1613 5.47772 40.3739 6.22416 40.4878C6.9717 40.5966 7.72738 40.6557 8.48488 40.6646C8.83358 40.6714 9.17621 40.6753 9.5216 40.6753C9.93419 40.6773 10.343 40.6773 10.7566 40.6773H124.249C124.655 40.6773 125.067 40.6773 125.472 40.6753C125.816 40.6753 126.169 40.6714 126.512 40.6646C127.268 40.6562 128.023 40.5971 128.769 40.4878C129.518 40.3731 130.243 40.1605 130.921 39.857C131.597 39.5531 132.213 39.1551 132.746 38.6783C133.283 38.2055 133.733 37.6607 134.079 37.064C134.42 36.4643 134.655 35.8222 134.777 35.1597C134.903 34.4979 134.973 33.8287 134.987 33.1578C134.991 32.8472 134.991 32.5464 134.991 32.2359C135 31.8726 135 31.5113 135 31.1421V10.2134C135 9.84717 135 9.48389 134.991 9.12159C134.991 8.81495 134.991 8.50733 134.987 8.20069C134.973 7.52968 134.903 6.86049 134.777 6.19869C134.655 5.53654 134.419 4.89476 134.079 4.29537C133.383 3.09245 132.278 2.11328 130.921 1.49557C130.243 1.19282 129.518 0.981494 128.769 0.868574C128.023 0.758357 127.269 0.699262 126.512 0.691814C126.169 0.686934 125.816 0.681074 125.472 0.679124C125.067 0.677124 124.655 0.677124 124.249 0.677124V0.677254Z"
                    fill="black"
                />
                <path
                    d="M9.52713 39.8022C9.18341 39.8022 8.84798 39.7983 8.50695 39.7915C7.80048 39.7834 7.09566 39.7289 6.39827 39.6284C5.74799 39.5292 5.11804 39.3445 4.5292 39.0805C3.94577 38.8188 3.41363 38.4755 2.95317 38.0639C2.48604 37.6572 2.09714 37.1855 1.80187 36.6674C1.50335 36.146 1.29675 35.5871 1.18928 35.0102C1.07322 34.3904 1.01043 33.7636 1.00144 33.1352C0.994288 32.9243 0.984924 32.2221 0.984924 32.2221V9.12159C0.984924 9.12159 0.994897 8.43018 1.0015 8.22705C1.0101 7.59973 1.07253 6.9739 1.18824 6.35498C1.29591 5.7765 1.50267 5.21599 1.80135 4.69288C2.09553 4.17519 2.48228 3.70311 2.94659 3.29493C3.41038 2.88287 3.94422 2.53785 4.52865 2.27246C5.11613 2.00934 5.74489 1.82598 6.39386 1.72852C7.09354 1.62708 7.80081 1.57224 8.50973 1.56446L9.52769 1.55225H125.467L126.497 1.56495C127.199 1.57235 127.9 1.6267 128.594 1.72754C129.249 1.82622 129.884 2.01086 130.479 2.27539C131.649 2.81023 132.602 3.65641 133.203 4.69532C133.497 5.21482 133.701 5.77075 133.807 6.34424C133.924 6.96824 133.99 7.59898 134.003 8.23145C134.006 8.51465 134.006 8.81885 134.006 9.12159C134.015 9.49659 134.015 9.85352 134.015 10.2134V31.142C134.015 31.5053 134.015 31.8598 134.006 32.2172C134.006 32.5424 134.006 32.8403 134.002 33.1469C133.989 33.7681 133.924 34.3876 133.809 35.0004C133.704 35.5815 133.498 36.1448 133.2 36.6704C132.902 37.1829 132.516 37.6505 132.054 38.0561C131.593 38.4699 131.06 38.8152 130.475 39.0786C129.883 39.3446 129.248 39.53 128.594 39.6284C127.896 39.7294 127.191 39.7839 126.485 39.7915C126.154 39.7983 125.808 39.8022 125.472 39.8022L124.249 39.8042L9.52713 39.8022Z"
                    fill="white"
                />
                <path
                    d="M31.0671 20.5666C31.0783 19.6986 31.3095 18.8476 31.7392 18.0927C32.1688 17.3377 32.783 16.7035 33.5245 16.2489C33.0535 15.578 32.432 15.0258 31.7095 14.6363C30.987 14.2468 30.1834 14.0307 29.3625 14.0051C27.6114 13.8218 25.9137 15.05 25.0213 15.05C24.1117 15.05 22.7378 14.0233 21.2583 14.0537C20.3014 14.0845 19.3687 14.362 18.5513 14.8591C17.7339 15.3562 17.0595 16.0561 16.5939 16.8904C14.5772 20.3724 16.0815 25.4897 18.0134 28.3044C18.98 29.6826 20.1096 31.2221 21.5878 31.1675C23.0342 31.1077 23.5745 30.2477 25.3204 30.2477C27.0502 30.2477 27.557 31.1675 29.0652 31.1328C30.6173 31.1077 31.5952 29.7484 32.5279 28.3572C33.2224 27.3751 33.7568 26.2898 34.1113 25.1413C33.2096 24.7609 32.4401 24.1243 31.8987 23.3108C31.3574 22.4973 31.0681 21.5429 31.0671 20.5666Z"
                    fill="black"
                />
                <path
                    d="M28.2185 12.1541C29.0648 11.141 29.4817 9.83885 29.3808 8.52417C28.0878 8.65958 26.8936 9.27578 26.0359 10.25C25.6165 10.7259 25.2953 11.2796 25.0907 11.8794C24.886 12.4792 24.8019 13.1133 24.8432 13.7455C25.4899 13.7521 26.1296 13.6124 26.7143 13.3367C27.299 13.061 27.8133 12.6567 28.2185 12.1541Z"
                    fill="black"
                />
                <path
                    d="M48.4205 27.8169H43.6739L42.5339 31.1734H40.5234L45.0194 18.7554H47.1083L51.6043 31.1734H49.5595L48.4205 27.8169ZM44.1655 26.2681H47.928L46.0732 20.8208H46.0212L44.1655 26.2681Z"
                    fill="black"
                />
                <path
                    d="M61.3141 26.6471C61.3141 29.4605 59.804 31.2682 57.5251 31.2682C56.9479 31.2983 56.3738 31.1657 55.8685 30.8855C55.3633 30.6054 54.9475 30.189 54.6685 29.6842H54.6254V34.1685H52.7618V22.1197H54.5656V23.6256H54.5999C54.8917 23.1231 55.3146 22.7089 55.8237 22.4271C56.3327 22.1452 56.9089 22.0063 57.4908 22.025C59.7952 22.025 61.3141 23.8414 61.3141 26.6471ZM59.3985 26.6471C59.3985 24.8141 58.4486 23.609 56.9992 23.609C55.5753 23.609 54.6176 24.8394 54.6176 26.6471C54.6176 28.4713 55.5753 29.693 56.9992 29.693C58.4486 29.693 59.3985 28.4967 59.3985 26.6471H59.3985Z"
                    fill="black"
                />
                <path
                    d="M71.3074 26.6471C71.3074 29.4605 69.7968 31.2682 67.5179 31.2682C66.9407 31.2983 66.3666 31.1657 65.8614 30.8855C65.3562 30.6054 64.9403 30.189 64.6613 29.6842H64.6182V34.1685H62.7546V22.1197H64.5585V23.6256H64.5927C64.8845 23.1231 65.3074 22.7089 65.8165 22.4271C66.3255 22.1452 66.9017 22.0063 67.4836 22.025C69.788 22.025 71.3074 23.8414 71.3074 26.6471ZM69.3914 26.6471C69.3914 24.8141 68.4414 23.609 66.9921 23.609C65.5682 23.609 64.6104 24.8394 64.6104 26.6471C64.6104 28.4713 65.5682 29.693 66.9921 29.693C68.4414 29.693 69.3914 28.4967 69.3914 26.6471Z"
                    fill="black"
                />
                <path
                    d="M77.9108 27.7134C78.0489 28.9449 79.2486 29.7534 80.8879 29.7534C82.4587 29.7534 83.5888 28.9448 83.5888 27.8345C83.5888 26.8706 82.9072 26.2935 81.2933 25.898L79.6794 25.5103C77.3928 24.9595 76.3312 23.8931 76.3312 22.1626C76.3312 20.02 78.2036 18.5483 80.8615 18.5483C83.4938 18.5483 85.2977 20.02 85.3584 22.1626H83.4772C83.3645 20.9233 82.3373 20.1753 80.836 20.1753C79.3347 20.1753 78.3074 20.9321 78.3074 22.0337C78.3074 22.9116 78.9636 23.4282 80.5686 23.8237L81.9406 24.1596C84.4956 24.7622 85.5562 25.7856 85.5562 27.602C85.5562 29.9253 83.7014 31.3803 80.7498 31.3803C77.9881 31.3803 76.1235 29.9594 76.0031 27.7133L77.9108 27.7134Z"
                    fill="black"
                />
                <path
                    d="M89.5802 19.9771V22.1196H91.3067V23.5913H89.5802V28.5825C89.5802 29.3579 89.9259 29.7192 90.6848 29.7192C90.8898 29.7157 91.0944 29.7013 91.2979 29.6762V31.1391C90.9567 31.2027 90.6098 31.2315 90.2628 31.2251C88.4246 31.2251 87.7078 30.5366 87.7078 28.7807V23.5913H86.3877V22.1196H87.7077V19.9771H89.5802Z"
                    fill="black"
                />
                <path
                    d="M92.3056 26.647C92.3056 23.7983 93.9881 22.0083 96.6116 22.0083C99.244 22.0083 100.919 23.7983 100.919 26.647C100.919 29.5034 99.2528 31.2856 96.6116 31.2856C93.9714 31.2856 92.3056 29.5034 92.3056 26.647ZM99.0197 26.647C99.0197 24.6929 98.1217 23.5396 96.6116 23.5396C95.1015 23.5396 94.2035 24.7017 94.2035 26.647C94.2035 28.6089 95.1015 29.7534 96.6116 29.7534C98.1217 29.7534 99.0197 28.6089 99.0197 26.647H99.0197Z"
                    fill="black"
                />
                <path
                    d="M102.455 22.1196H104.233V23.6606H104.276C104.396 23.1793 104.679 22.754 105.076 22.4561C105.474 22.1582 105.962 22.006 106.46 22.0249C106.674 22.0241 106.888 22.0474 107.098 22.0942V23.8325C106.827 23.7499 106.544 23.712 106.261 23.7202C105.99 23.7093 105.72 23.7569 105.469 23.8597C105.219 23.9626 104.994 24.1184 104.809 24.3163C104.625 24.5141 104.485 24.7495 104.401 25.0062C104.316 25.2628 104.288 25.5347 104.319 25.8032V31.1733H102.455L102.455 22.1196Z"
                    fill="black"
                />
                <path
                    d="M115.69 28.5142C115.44 30.1577 113.835 31.2856 111.781 31.2856C109.14 31.2856 107.5 29.521 107.5 26.6899C107.5 23.8501 109.149 22.0083 111.703 22.0083C114.215 22.0083 115.794 23.729 115.794 26.4741V27.1108H109.382V27.2231C109.352 27.5563 109.394 27.892 109.504 28.2078C109.615 28.5237 109.792 28.8125 110.023 29.055C110.254 29.2976 110.534 29.4883 110.844 29.6146C111.155 29.7409 111.489 29.7999 111.824 29.7876C112.265 29.8287 112.706 29.727 113.084 29.4976C113.462 29.2681 113.755 28.9232 113.921 28.5142L115.69 28.5142ZM109.391 25.812H113.93C113.946 25.5124 113.901 25.2126 113.796 24.9314C113.691 24.6502 113.529 24.3936 113.32 24.1779C113.111 23.9621 112.859 23.7918 112.581 23.6776C112.302 23.5633 112.004 23.5077 111.703 23.5142C111.399 23.5124 111.098 23.5705 110.818 23.6852C110.537 23.8 110.281 23.9691 110.066 24.1827C109.852 24.3963 109.681 24.6503 109.565 24.9299C109.449 25.2096 109.39 25.5094 109.391 25.812V25.812Z"
                    fill="black"
                />
                <path
                    d="M43.9323 9.40826C44.323 9.3803 44.7151 9.43916 45.0802 9.58059C45.4454 9.72203 45.7745 9.94251 46.0438 10.2261C46.3131 10.5098 46.516 10.8495 46.6377 11.2207C46.7595 11.592 46.7971 11.9856 46.7478 12.3731C46.7478 14.2794 45.7147 15.3751 43.9323 15.3751H41.771V9.40826H43.9323ZM42.7003 14.5313H43.8285C44.1077 14.5479 44.3871 14.5023 44.6464 14.3976C44.9056 14.293 45.1382 14.1321 45.3273 13.9265C45.5163 13.7209 45.6569 13.4759 45.739 13.2093C45.8211 12.9427 45.8426 12.6612 45.8018 12.3853C45.8396 12.1104 45.816 11.8307 45.7326 11.566C45.6493 11.3013 45.5083 11.0583 45.3197 10.8544C45.1311 10.6505 44.8996 10.4907 44.6418 10.3864C44.384 10.2822 44.1063 10.2361 43.8285 10.2515H42.7003V14.5313Z"
                    fill="black"
                />
                <path
                    d="M47.7976 13.1217C47.7692 12.8257 47.8032 12.5272 47.8973 12.2451C47.9914 11.963 48.1436 11.7037 48.3441 11.4837C48.5446 11.2637 48.789 11.088 49.0616 10.9677C49.3342 10.8474 49.629 10.7853 49.9271 10.7853C50.2252 10.7853 50.52 10.8474 50.7927 10.9677C51.0653 11.088 51.3097 11.2637 51.5102 11.4837C51.7107 11.7037 51.8629 11.963 51.957 12.2451C52.0511 12.5272 52.085 12.8257 52.0566 13.1217C52.0855 13.4179 52.052 13.7169 51.9581 13.9994C51.8643 14.2819 51.7122 14.5417 51.5116 14.7621C51.3111 14.9826 51.0665 15.1587 50.7936 15.2792C50.5207 15.3998 50.2256 15.462 49.9271 15.462C49.6287 15.462 49.3335 15.3998 49.0606 15.2792C48.7877 15.1587 48.5432 14.9826 48.3426 14.7621C48.142 14.5417 47.9899 14.2819 47.8961 13.9994C47.8022 13.7169 47.7687 13.4179 47.7976 13.1217ZM51.14 13.1217C51.14 12.1456 50.7003 11.5748 49.9286 11.5748C49.154 11.5748 48.7182 12.1456 48.7182 13.1217C48.7182 14.1056 49.154 14.672 49.9286 14.672C50.7003 14.6719 51.14 14.1016 51.14 13.1217H51.14Z"
                    fill="black"
                />
                <path
                    d="M57.718 15.375H56.7935L55.8602 12.0586H55.7897L54.8604 15.375H53.9447L52.7 10.8721H53.6039L54.4128 14.3081H54.4794L55.4078 10.8721H56.2627L57.1911 14.3081H57.2616L58.0666 10.8721H58.9578L57.718 15.375Z"
                    fill="black"
                />
                <path
                    d="M60.0047 10.8721H60.8625V11.5874H60.9291C61.0421 11.3305 61.2326 11.1152 61.4742 10.9713C61.7157 10.8275 61.9962 10.7625 62.2766 10.7852C62.4964 10.7687 62.717 10.8018 62.9221 10.8819C63.1273 10.9619 63.3118 11.0871 63.4619 11.2479C63.612 11.4087 63.7239 11.6012 63.7893 11.811C63.8546 12.0208 63.8719 12.2426 63.8396 12.46V15.375H62.9484V12.6832C62.9484 11.9595 62.6331 11.5997 61.974 11.5997C61.8249 11.5927 61.6759 11.618 61.5375 11.6739C61.3991 11.7297 61.2744 11.8147 61.1719 11.9231C61.0695 12.0315 60.9918 12.1607 60.9442 12.3018C60.8965 12.4429 60.88 12.5927 60.8958 12.7408V15.375H60.0046L60.0047 10.8721Z"
                    fill="black"
                />
                <path d="M65.2596 9.11426H66.1508V15.375H65.2596V9.11426Z" fill="black" />
                <path
                    d="M67.3896 13.1216C67.3612 12.8257 67.3952 12.5271 67.4893 12.245C67.5834 11.9629 67.7356 11.7036 67.9362 11.4836C68.1367 11.2636 68.3811 11.0878 68.6537 10.9676C68.9264 10.8473 69.2212 10.7852 69.5193 10.7852C69.8174 10.7852 70.1123 10.8473 70.3849 10.9676C70.6575 11.0878 70.902 11.2636 71.1025 11.4836C71.303 11.7036 71.4552 11.9629 71.5493 12.245C71.6435 12.5271 71.6774 12.8257 71.6491 13.1216C71.678 13.4179 71.6444 13.7168 71.5505 13.9994C71.4566 14.2819 71.3045 14.5417 71.1039 14.7621C70.9033 14.9825 70.6587 15.1586 70.3859 15.2792C70.113 15.3997 69.8178 15.462 69.5193 15.462C69.2209 15.462 68.9257 15.3997 68.6528 15.2792C68.3799 15.1586 68.1353 14.9825 67.9347 14.7621C67.7341 14.5417 67.582 14.2819 67.4881 13.9994C67.3943 13.7168 67.3607 13.4179 67.3896 13.1216ZM70.7319 13.1216C70.7319 12.1455 70.2922 11.5747 69.5205 11.5747C68.7459 11.5747 68.3102 12.1455 68.3102 13.1216C68.3102 14.1055 68.7459 14.6719 69.5205 14.6719C70.2922 14.6719 70.7319 14.1016 70.7319 13.1216H70.7319Z"
                    fill="black"
                />
                <path
                    d="M72.5872 14.1016C72.5872 13.291 73.1925 12.8237 74.2667 12.7573L75.4899 12.687V12.2983C75.4899 11.8227 75.1746 11.5542 74.5654 11.5542C74.068 11.5542 73.7232 11.7363 73.6243 12.0547H72.7616C72.8526 11.2812 73.5822 10.7852 74.6066 10.7852C75.7386 10.7852 76.3771 11.3472 76.3771 12.2983V15.375H75.5193V14.7422H75.4488C75.3057 14.9692 75.1047 15.1542 74.8664 15.2784C74.6281 15.4026 74.361 15.4616 74.0924 15.4492C73.9029 15.4688 73.7113 15.4487 73.53 15.39C73.3487 15.3313 73.1818 15.2354 73.04 15.1084C72.8982 14.9814 72.7846 14.8262 72.7066 14.6528C72.6287 14.4794 72.588 14.2916 72.5872 14.1016ZM75.4899 13.7168V13.3403L74.3872 13.4106C73.7653 13.4521 73.4833 13.6631 73.4833 14.06C73.4833 14.4653 73.8358 14.7012 74.3206 14.7012C74.4627 14.7155 74.6062 14.7012 74.7426 14.6591C74.8789 14.617 75.0054 14.548 75.1145 14.4562C75.2236 14.3643 75.3131 14.2515 75.3776 14.1245C75.442 13.9975 75.4803 13.8588 75.4899 13.7168Z"
                    fill="black"
                />
                <path
                    d="M77.5484 13.1216C77.5484 11.6987 78.2819 10.7974 79.4228 10.7974C79.705 10.7844 79.9851 10.8518 80.2303 10.9917C80.4755 11.1316 80.6757 11.3382 80.8075 11.5874H80.8741V9.11426H81.7653V15.375H80.9113V14.6636H80.8408C80.6988 14.9111 80.4916 15.1151 80.2417 15.2536C79.9917 15.3921 79.7085 15.4597 79.4228 15.4492C78.2741 15.4493 77.5484 14.5479 77.5484 13.1216ZM78.469 13.1216C78.469 14.0767 78.9204 14.6514 79.6755 14.6514C80.4266 14.6514 80.8908 14.0684 80.8908 13.1255C80.8908 12.187 80.4217 11.5957 79.6755 11.5957C78.9253 11.5957 78.4689 12.1743 78.4689 13.1216H78.469Z"
                    fill="black"
                />
                <path
                    d="M85.4524 13.1217C85.424 12.8257 85.4579 12.5272 85.552 12.2451C85.6461 11.963 85.7983 11.7037 85.9988 11.4837C86.1993 11.2637 86.4437 11.088 86.7163 10.9677C86.9889 10.8474 87.2838 10.7853 87.5819 10.7853C87.88 10.7853 88.1748 10.8474 88.4474 10.9677C88.72 11.088 88.9644 11.2637 89.1649 11.4837C89.3654 11.7037 89.5176 11.963 89.6117 12.2451C89.7058 12.5272 89.7397 12.8257 89.7114 13.1217C89.7403 13.4179 89.7067 13.7169 89.6129 13.9994C89.519 14.2819 89.3669 14.5417 89.1664 14.7621C88.9658 14.9826 88.7212 15.1587 88.4484 15.2792C88.1755 15.3998 87.8803 15.462 87.5819 15.462C87.2834 15.462 86.9882 15.3998 86.7153 15.2792C86.4425 15.1587 86.1979 14.9826 85.9973 14.7621C85.7968 14.5417 85.6447 14.2819 85.5508 13.9994C85.457 13.7169 85.4234 13.4179 85.4524 13.1217ZM88.7947 13.1217C88.7947 12.1456 88.355 11.5748 87.5833 11.5748C86.8087 11.5748 86.3729 12.1456 86.3729 13.1217C86.3729 14.1056 86.8087 14.672 87.5833 14.672C88.355 14.6719 88.7947 14.1016 88.7947 13.1217Z"
                    fill="black"
                />
                <path
                    d="M90.9071 10.8721H91.7649V11.5874H91.8315C91.9445 11.3305 92.135 11.1152 92.3766 10.9713C92.6181 10.8275 92.8987 10.7625 93.1791 10.7852C93.3988 10.7687 93.6194 10.8018 93.8246 10.8819C94.0297 10.9619 94.2142 11.0871 94.3643 11.2479C94.5144 11.4087 94.6263 11.6012 94.6917 11.811C94.7571 12.0208 94.7743 12.2426 94.742 12.46V15.375H93.8509V12.6832C93.8509 11.9595 93.5355 11.5997 92.8765 11.5997C92.7273 11.5927 92.5784 11.618 92.4399 11.6739C92.3015 11.7297 92.1768 11.8147 92.0744 11.9231C91.972 12.0315 91.8943 12.1607 91.8466 12.3018C91.7989 12.4429 91.7824 12.5927 91.7982 12.7408V15.375H90.9071V10.8721Z"
                    fill="black"
                />
                <path
                    d="M99.7776 9.75098V10.8926H100.756V11.6411H99.7776V13.9565C99.7776 14.4282 99.9725 14.6348 100.416 14.6348C100.53 14.6344 100.643 14.6276 100.756 14.6143V15.3545C100.596 15.383 100.434 15.3982 100.271 15.3999C99.2801 15.3999 98.8855 15.0522 98.8855 14.1841V11.6411H98.1686V10.8925H98.8855V9.75098H99.7776Z"
                    fill="black"
                />
                <path
                    d="M101.973 9.11426H102.857V11.5957H102.927C103.046 11.3364 103.241 11.1198 103.488 10.9754C103.734 10.8311 104.019 10.766 104.304 10.7891C104.522 10.7772 104.741 10.8136 104.944 10.8956C105.147 10.9777 105.329 11.1034 105.477 11.2637C105.626 11.424 105.737 11.6151 105.803 11.8232C105.869 12.0314 105.888 12.2514 105.859 12.4678V15.375H104.967V12.687C104.967 11.9678 104.631 11.6035 104.001 11.6035C103.848 11.591 103.694 11.612 103.55 11.665C103.406 11.7181 103.275 11.8019 103.167 11.9107C103.058 12.0194 102.975 12.1504 102.923 12.2945C102.871 12.4387 102.851 12.5925 102.864 12.7451V15.375H101.973L101.973 9.11426Z"
                    fill="black"
                />
                <path
                    d="M111.055 14.1591C110.934 14.5707 110.672 14.9267 110.314 15.1648C109.956 15.403 109.526 15.5081 109.099 15.4619C108.801 15.4697 108.505 15.4129 108.232 15.2953C107.959 15.1778 107.715 15.0024 107.516 14.7813C107.318 14.5601 107.17 14.2986 107.083 14.0147C106.996 13.7309 106.972 13.4316 107.013 13.1376C106.973 12.8428 106.997 12.5428 107.084 12.2582C107.171 11.9735 107.318 11.7107 107.515 11.4876C107.713 11.2646 107.956 11.0864 108.228 10.9652C108.501 10.844 108.796 10.7826 109.095 10.7851C110.351 10.7851 111.109 11.6411 111.109 13.0551V13.3652H107.921V13.415C107.907 13.5802 107.927 13.7466 107.982 13.9033C108.036 14.0601 108.122 14.2038 108.236 14.3252C108.349 14.4467 108.486 14.5432 108.639 14.6086C108.792 14.674 108.957 14.7068 109.123 14.705C109.336 14.7305 109.552 14.6922 109.744 14.595C109.935 14.4978 110.093 14.3461 110.197 14.1591L111.055 14.1591ZM107.921 12.708H110.201C110.213 12.5568 110.192 12.405 110.141 12.2623C110.09 12.1196 110.009 11.9891 109.905 11.8793C109.8 11.7694 109.673 11.6826 109.533 11.6244C109.393 11.5662 109.242 11.538 109.09 11.5415C108.936 11.5395 108.783 11.5684 108.64 11.6263C108.497 11.6843 108.367 11.7701 108.259 11.8788C108.15 11.9876 108.063 12.1169 108.005 12.2593C107.947 12.4017 107.919 12.5543 107.921 12.708H107.921Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_5129_19700">
                    <rect width="135" height="40.0001" fill="white" transform="translate(0 0.677246)" />
                </clipPath>
            </defs>
        </svg>
    )
}
