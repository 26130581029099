import { Suspense } from 'react'
import styled, { css } from 'styled-components'

import { LaunchApp } from './components/launchApp'
import { MarketLinks } from './components/marketLink/marketLinks'
import { QRCode } from './components/qrcode'
import Title from './components/title'
import { isMobile } from './device'

function App() {
    return (
        <Container>
            <CenterContainer isMobile={isMobile}>
                <Suspense fallback="Loading...">
                    <QrcodeWrapper>
                        <Title isMobile={isMobile} />
                        <QRCode />
                    </QrcodeWrapper>
                    <LaunchApp />
                    <MarketLinks />
                </Suspense>
            </CenterContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
`

const CenterContainer = styled.div<{ isMobile: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    ${({ isMobile }) =>
        !isMobile &&
        css`
            gap: 184px;

            @media only screen and (max-width: 768px) {
                flex-direction: column;
                gap: 127px;
            }
        `}
    text-align: center;
`

const QrcodeWrapper = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
`

export default App
