import { Fragment } from 'react'
import { CustomTypeOptions, useTranslation } from 'react-i18next'

type LocaleKeys = keyof CustomTypeOptions['resources']['en' | 'ko']

export const useTrans = (key: LocaleKeys) => {
    const { t } = useTranslation()

    const text = t(key)

    return text.split('\n').map((str, index, arr) => (
        <Fragment key={index}>
            {str}
            {index < arr.length - 1 && <br />}
        </Fragment>
    ))
}
