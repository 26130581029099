import { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import parseUA from 'ua-parser-js'

import { ROUTES, STORE_LINKS } from '../constants'
import { useTrans } from '../hooks/useTrans'

const {
    device: { type },
    os,
} = parseUA(window.navigator.userAgent)
const isMobile = ['mobile', 'tablet', 'wearable'].includes(type || '')

const encodeUri = (uri: string, osName?: string) => {
    const result = encodeURIComponent(uri)

    return result
}

const createUri = () => {
    const path = window.location.href.replace(window.location.origin, '')

    switch (true) {
        case path.startsWith(ROUTES.AIRDROP): {
            const requestKey = path.replace(ROUTES.AIRDROP, '')
            return `/api?request_key=${requestKey}`
        }

        case path.startsWith(ROUTES.REDIRECT): {
            const url = path.replace(ROUTES.REDIRECT, '')
            const encoded = encodeUri(url, os.name)
            return `/browser?url=${encoded}`
        }

        case path.startsWith(ROUTES.DEEPLINK): {
            const deeplink = path.replace(ROUTES.DEEPLINK, '')
            return `/${deeplink}`
        }

        default: {
            // return '/'
            return '/api' // rollback after release v3.1.2
        }
    }
}

export const LaunchApp = () => {
    const uri = useMemo(createUri, [])
    const launchText = useTrans('launch')

    const redirectToApp = useCallback(() => {
        window.daumtools.web2app({
            urlScheme: `kaiawallet://wallet${uri}`,
            intentURI: `intent://wallet${uri}#Intent;scheme=kaiawallet;package=io.klutch.wallet;end`,
            appName: 'Kaia Wallet',
            storeURL: (STORE_LINKS as Record<string, string | undefined>)[os.name || ''],
        })
    }, [uri])

    useEffect(() => {
        if (isMobile) {
            // 모바일은 앱 자동 실행
            window.setTimeout(redirectToApp, 500)
        }
    }, [redirectToApp])

    if (!isMobile) {
        return null
    }

    return <Button onClick={redirectToApp}>{launchText}</Button>
}

const Button = styled.button`
    width: 244px;
    padding: 14px 16px;
    text-align: center;
    background: #3366ff;
    border-radius: 12px;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
`
