import parseUA from 'ua-parser-js'
const {
    device: { type },
    os,
} = parseUA(window.navigator.userAgent)

const isMobile = ['mobile', 'tablet', 'wearable'].includes(type || '')
const isAndroid = os.name === 'Android'
const isiOS = os.name === 'iOS'

export { isMobile, isAndroid, isiOS }
